import React, { Component, ChangeEvent } from 'react';
import Redux from 'redux';
import { connect } from 'react-redux';
import AppState from '../types/AppState';
import Seuqence from './Seuqence';
import gameService from '../services/gameService';
import Condition from '../types/Condition';
import ConditionSelect from './ConditionSelect';
import { SelectorType } from '../types/SelectorType';
import { StatisticService } from '../services/statisticService';
import storeService from '../services/storeService';
import integrationService from '../services/integrationService';
import DifficultyLevelSettings from '../types/DifficultyLevelSettings';
import RoundState from '../types/RoundState';
import actions from '../store/actions';
import graylogService from '../services/graylogServices';
import dispatcherService from '../services/dispatcherService';
import { message } from 'antd';
import IntegrationParams from '../types/IntegrationParams';

interface StateProps {
    settings: DifficultyLevelSettings[];
    currentDifficultyLevelIndex: number;
    currentRoundState: RoundState;
    currentRoundIndex: number;
    integrationParams: IntegrationParams;
}

interface DispatchProps {
    setCurrentRoundState: (state: RoundState) => void;
    setCurrentRoundIndex: (roundIndex: number) => void;
    setCurrentDifficultyLevelIndex: (difficultyLevelIndex: number) => void;
}

type IGameProps = StateProps & DispatchProps;

interface IGameState {
    // for selectors
    conditionIf: Condition;
    conditionAndOr: Condition;
    conditionThen: Condition;
    isEndOfRound: boolean;
    isUserGuessedCorrectly: boolean;
    isNextDifficultyLevelAvailable: boolean;
    isCurrentDifficultyLevelTheLastOne: boolean;
    retryCount: number;
    retryDeadline: number;
    retrying: boolean;
}

class Game extends Component<IGameProps, IGameState> {
    private statisticService: StatisticService;
    constructor(props: IGameProps) {
        super(props);

        this.state = {
            conditionIf: 'big',
            conditionAndOr: 'big',
            conditionThen: 'big',
            isEndOfRound: false,
            isUserGuessedCorrectly: false,
            isNextDifficultyLevelAvailable: false,
            isCurrentDifficultyLevelTheLastOne: false, // TODO isn't it just !isNextDifficultyLevelAvailable?
            retryCount: 0,
            retryDeadline: this.getCountdown(0),
            retrying: false,
        };

        this.statisticService = new StatisticService(
            this.props.settings[this.props.currentDifficultyLevelIndex]
        );
    }

    getCountdown(count: number) {
        return count >= 3 ? 15 * count : count === 1 ? 20 : count === 2 ? 30 : 15;
    }

    resetCountdown() {
        this.setState({
            retryCount: this.state.retryCount + 1,
            retryDeadline: this.getCountdown(this.state.retryCount),
        });
    }

    componentDidUpdate(
        prevProps: Readonly<IGameProps>,
        prevState: Readonly<IGameState>,
        snapshot?: any
    ) {
        this.updateProgress();
    }

    onElementSelected = (event: ChangeEvent<HTMLSelectElement>) => {
        const targetName: SelectorType = +event.target.name;
        const stateKey = SelectorType[targetName];
        this.setState({ ...this.state, [stateKey]: event.target.value });
    };

    getRoundCountInCurrentDifficultyLevel = (currentDifficultyLevelIndex: number): number => {
        return this.props.settings[currentDifficultyLevelIndex].roundsCount;
    };

    endGame = async () => {
        document
            .querySelectorAll<HTMLElement>('.game-footer .button')
            .forEach(item => (item.hidden = true));

        const isFinished = await this.finish();
        if (!isFinished) {
            await this.retrySendResult();
        } else {
            window.top?.postMessage('FINISH_ACTIVITY', '*');
        }
    };

    async retrySendResult() {
        console.log('Retry send result...');
        this.setState({
            retrying: true,
        });

        const self = this;

        const interval = setInterval(async function() {
            self.setState({
                retryDeadline: self.state.retryDeadline - 1,
            });

            if (self.state.retryDeadline === 0) {
                clearInterval(interval);

                const isFinished = await self.finish();
                if (!isFinished) {
                    self.resetCountdown();
                    await self.retrySendResult();
                } else {
                    document
                        .querySelectorAll<HTMLElement>('.game-footer .button')
                        .forEach(item => (item.hidden = true));
                    message.success('Активность успешно пройдена!');

                    self.setState({
                        retrying: false,
                    });
                }
            }
        }, 1000);
    }

    async finish() {
        const integrationParams = storeService.getStore().getState().general.integrationParams;

        let sendResultRequest = await integrationService.sendResult(
            integrationParams,
            this.statisticService.getSessionStatistic()
        );

        if (sendResultRequest.status !== 200) {
            await graylogService.sendMessage({
                short_message: `[${sendResultRequest.status}]  Request error: ${sendResultRequest.url}`,
                full_message: `[${sendResultRequest.status}]  Request error: ${sendResultRequest.url}`,
                _body: integrationService.buildLrsBody(
                    integrationParams,
                    this.statisticService.getSessionStatistic()
                ),
                _body_result: await sendResultRequest.text(),
            });

            // dispatcherService.setMode('warning');
            // dispatcherService.setWarningType('result');
        } else {
            await integrationService.sendEnd(integrationParams);

            document
                .querySelectorAll<HTMLElement>('.game-footer .button')
                .forEach(item => (item.hidden = true));
            message.success('Активность успешно пройдена!');

            this.setState({
                retrying: false,
            });
        }

        return sendResultRequest.status === 200;
    }

    nextRound = () => {
        this.setState({ isEndOfRound: false });
        this.props.setCurrentRoundIndex(this.props.currentRoundIndex + 1);
        const dufficultyLevelIndex = this.props.currentDifficultyLevelIndex;
        gameService.newRound(this.props.settings[dufficultyLevelIndex]);
        this.statisticService.passRound(this.state.isUserGuessedCorrectly); // STATISTIC
        this.setState({ isUserGuessedCorrectly: false });
    };

    nextLevel = () => {
        const newDifficultyLevel = this.props.currentDifficultyLevelIndex + 1;

        if (this.props.settings && newDifficultyLevel <= this.props.settings.length - 1) {
            this.props.setCurrentRoundIndex(0);
            this.props.setCurrentDifficultyLevelIndex(newDifficultyLevel);

            gameService.newRound(this.props.settings[newDifficultyLevel]); // next level
            this.statisticService.newLevel(this.props.settings[newDifficultyLevel]); // STATISTIC

            this.setState({ isNextDifficultyLevelAvailable: false, isEndOfRound: false });
        } else {
            // no more levels to play
            this.setState({
                isNextDifficultyLevelAvailable: false,
                isCurrentDifficultyLevelTheLastOne: true,
                isEndOfRound: false,
            });
        }
    };

    getCurrentUserRule = () => {
        return {
            type: this.props.currentRoundState.rule.type, // user had entered conditions according to current round rule type
            conditionIf: this.state.conditionIf,
            conditionAndOr: this.state.conditionAndOr || null,
            conditionThen: this.state.conditionThen,
        };
    };

    addNewSequenceToCurrentRound = () => {
        const length = this.props.currentRoundState.settings.sequenceLength;
        const newSequence = gameService.generateRandomSequence(
            this.props.currentRoundState.rule,
            length
        );
        this.props.currentRoundState.sequences.push(newSequence);
        this.props.currentRoundState.sequenceCount++;
    };

    // TODO break method down to several methods or move logic to service
    handleAnswer = () => {
        const currentRound = { ...this.props.currentRoundState };
        const userRule = this.getCurrentUserRule();

        const userAnswerIsRight = gameService.checkUserAnswer(userRule, currentRound.sequences);

        const roundCountInCurrentDifficultyLevel = this.getRoundCountInCurrentDifficultyLevel(
            this.props.currentDifficultyLevelIndex
        );
        const currentRoundIndex = this.props.currentRoundIndex;

        if (userAnswerIsRight) {
            this.statisticService.rightAnswer(); // STATISTIC
            this.setState({ isEndOfRound: true, isUserGuessedCorrectly: true });
        } else {
            currentRound.attemptsLeft--;
            this.statisticService.wrongAnswer(); // STATISTIC
            if (currentRound.attemptsLeft > 0) {
                this.addNewSequenceToCurrentRound();
            } else {
                this.setState({ isEndOfRound: true });
            }
        }

        const isCurrentDifficultyLevelTheLastOne =
            this.props.currentDifficultyLevelIndex + 1 === this.props.settings.length;

        const isCurrentRoundTheLastOne =
            currentRoundIndex + 1 === roundCountInCurrentDifficultyLevel;

        if (isCurrentRoundTheLastOne && (currentRound.attemptsLeft === 0 || userAnswerIsRight)) {
            this.statisticService.passLevel(userAnswerIsRight); // STATISTIC
            this.setState({
                isNextDifficultyLevelAvailable: true,
                isCurrentDifficultyLevelTheLastOne: isCurrentDifficultyLevelTheLastOne,
            });
        }

        // apply all the changes to currentRound
        this.props.setCurrentRoundState(currentRound);
    };

    updateProgress = () => {
        (async () => {
            const {
                settings,
                currentRoundState,
                currentDifficultyLevelIndex,
                currentRoundIndex,
                integrationParams,
            } = this.props;

            let allRoundsLength = 0;
            settings.forEach(settingsItem => {
                allRoundsLength +=
                    settingsItem.roundsCount * settingsItem.roundSettings.attemptsCount;
            });

            let currentRoundsStage = 0;
            let currentRoundStage =
                currentRoundState.sequenceCount - currentRoundState.attemptsLeft;

            settings.forEach((settingsItem, settingsIndex) => {
                if (settingsIndex === currentDifficultyLevelIndex) {
                    currentRoundsStage +=
                        currentRoundIndex * settingsItem.roundSettings.attemptsCount +
                        currentRoundStage;
                } else if (settingsIndex < currentDifficultyLevelIndex) {
                    currentRoundsStage +=
                        settingsItem.roundsCount * settingsItem.roundSettings.attemptsCount;
                }
            });

            await integrationService.reportProgress(
                integrationParams,
                Math.floor((currentRoundsStage / allRoundsLength) * 100)
            );
        })();
    };

    private btnCase = {
        checkBlock: (
            <div>
                <button
                    className="button button_l button_red game__button"
                    onClick={this.handleAnswer}
                >
                    Проверить
                </button>
            </div>
        ),
        nextRoundBlock: (
            <div>
                {/*<div className="game__success">Правильно!</div>*/}
                <button
                    className="button button_l button_red game__button"
                    onClick={this.nextRound}
                >
                    Следующий раунд
                </button>
            </div>
        ),
        attemptsLeftBlock: (
            <div>
                {/*<div className="game__fail">Неправильно!</div>*/}
                <button
                    className="button button_l button_red game__button"
                    onClick={this.nextRound}
                >
                    Следующий раунд
                </button>
            </div>
        ),
        difficultyLevelDoneBlock: (
            <div>
                <div className="game__success">Сложность пройдена!</div>
                <button
                    className="button button_l button_red game__button"
                    onClick={this.nextLevel}
                >
                    Следующий уровень
                </button>
                <button className="button button_l button_red game__button" onClick={this.endGame}>
                    Завершить игру
                </button>
            </div>
        ),
        gameFinishBlock: (
            <div>
                <div className="game__success">Игра пройдена!</div>
                <button className="button button_l button_red game__button" onClick={this.endGame}>
                    Завершить игру
                </button>
            </div>
        ),
    };

    private alertCase = {
        rightBlock: <div className="game__success">Правильно!</div>,
        failBlock: <div className="game__fail">Неправильно!</div>,
    };

    private getBtnCase = () => {
        if (
            this.props.currentRoundState.attemptsLeft > 0 &&
            !this.state.isNextDifficultyLevelAvailable
        ) {
            const block = this.state.isEndOfRound
                ? this.btnCase.nextRoundBlock
                : this.btnCase.checkBlock;
            return block;
        } else if (
            this.props.currentRoundState.attemptsLeft === 0 &&
            !this.state.isNextDifficultyLevelAvailable
        ) {
            return this.btnCase.attemptsLeftBlock;
        } else if (this.state.isCurrentDifficultyLevelTheLastOne) {
            return this.btnCase.gameFinishBlock;
        } else if (this.state.isNextDifficultyLevelAvailable) {
            return this.btnCase.difficultyLevelDoneBlock;
        }
    };

    private getAlertCase = () => {
        if (this.props.currentRoundState.attemptsLeft === 0) {
            return this.alertCase.failBlock;
        } else if (this.props.currentRoundState.attemptsLeft > 0 && this.state.isEndOfRound) {
            return this.alertCase.rightBlock;
        }
    };

    render() {
        const rows = this.props.currentRoundState.sequences.map((sequence, index) => (
            <Seuqence key={'row' + index} sequence={sequence} />
        ));

        const roundCount = this.getRoundCountInCurrentDifficultyLevel(
            this.props.currentDifficultyLevelIndex
        );

        const conditionIf = (
            <ConditionSelect
                type={SelectorType.conditionIf}
                selectedElement={this.state.conditionIf}
                onElementSelected={this.onElementSelected}
            />
        );

        const conditionThen = (
            <ConditionSelect
                type={SelectorType.conditionThen}
                selectedElement={this.state.conditionThen}
                onElementSelected={this.onElementSelected}
            />
        );

        const currentRuleType = this.props.currentRoundState.rule.type;
        const conditionAndOr =
            currentRuleType !== 'single' ? (
                <ConditionSelect
                    type={SelectorType.conditionAndOr}
                    selectedElement={this.state.conditionAndOr}
                    onElementSelected={this.onElementSelected}
                />
            ) : null;
        const additionalCondition =
            currentRuleType === 'and' ? (
                <>
                    <span className="game-choice__line" />
                    <b className="game-choice__condition">и</b>
                </>
            ) : currentRuleType === 'or' ? (
                <>
                    <span className="game-choice__line" />
                    <b className="game-choice__condition">или</b>
                </>
            ) : null;

        return (
            <div className="game-status">
                <div className="game-settings">
                    <p>Текущий уровень сложности: {this.props.currentDifficultyLevelIndex + 1}</p>
                    <p>
                        Текущий раунд: {this.props.currentRoundIndex + 1}/{roundCount}
                    </p>
                    <p>Осталось попыток: {this.props.currentRoundState.attemptsLeft}</p>
                </div>
                <div className="game-body">{rows}</div>
                <div className="game-choice">
                    <span>Правило:</span>
                    <span className="game-choice__line" />
                    <b className="game-choice__condition">если</b>
                    {conditionIf}
                    {additionalCondition}
                    {conditionAndOr}
                    <span className="game-choice__line" />
                    <b className="game-choice__condition">,то</b> {conditionThen}
                </div>
                <div className="game-footer">
                    {this.getAlertCase()}
                    {this.getBtnCase()}
                    {this.state.retrying && (
                        <div className="game__fail">
                            Извините, ваши данные не удалось сохранить. Сейчас мы попробуем ещё раз.
                            Если данные не сохранятся, вы можете начать сначала или обратиться в
                            поддержку.
                            <br /> Повторная попытка через: {this.state.retryDeadline} секунд
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    settings: state.general.settings,
    currentDifficultyLevelIndex: state.general.currentDifficultyLevelIndex,
    currentRoundState: state.general.currentRoundState,
    currentRoundIndex: state.general.currentRoundIndex,
    integrationParams: state.general.integrationParams,
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => {
    return {
        setCurrentRoundState: (state: RoundState) =>
            dispatch(actions.general.setCurrentRoundState(state)),
        setCurrentRoundIndex: (roundIndex: number) =>
            dispatch(actions.general.setCurrentRoundIndex(roundIndex)),
        setCurrentDifficultyLevelIndex: (difficultyLevelIndex: number) =>
            dispatch(actions.general.setCurrentDifficultyLevelIndex(difficultyLevelIndex)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Game);
